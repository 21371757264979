import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environment/environment";
import { Observable, map } from "rxjs";
import moment from "moment";
import { RoleName } from "../enum/role.enum";

@Injectable({
    providedIn: 'root',
})
export class AuditService {
    constructor(
        private http: HttpClient
    ) { }

    // Get events for audit listing filter
    getEvent(): Observable<any> {
        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.auditPath}/events`).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Get audit listing
    getHistory(event: string, role: string, page: number, size: number, status: string, search: string = '', activePeriod: Date[] = []): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {
            "page": page,
            "size": size,
            "event": event !== 'ALL' ? event : null,
            "status": status !== 'ALL' ? status : null,
            "role": role !== 'ALL' ? (RoleName[role.toUpperCase().replace(/ /g, '_') as keyof typeof RoleName]) : null,
            "search": search !== '' ? search : null,
            "from": activePeriod.length === 2 ? (activePeriod[0] ? `${moment(activePeriod[0]).format("YYYYMMDD")}000000` : null) : null,
            "to": activePeriod.length === 2 ? (activePeriod[1] ? `${moment(activePeriod[1]).format("YYYYMMDD")}235959` : null) : null,
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.auditPath}/history`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Get statuses for audit listing filter
    getStatus(): Observable<any> {
        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.auditPath}/statuses`).pipe(
            map(res => {
                return res;
            })
        );
    }

}